import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`A Decisive Moment`}</h1>
    <p>{`2016 was a hard year. I was already working as a flight attendant and I enjoyed an amazing 2 years flying already.  In those two years - from 2014 to 2016 - I have travelled to a lot of different countries and beaten my goal to read 45 books in a single year.`}</p>
    <p>{`2016 was a hard year, mostly because of the summer. Strikes, bad weather, extremely long days, delays and little to no rest made everyone grumpy and annoyed at any little mishap. `}</p>
    <h1>{`It all started with a shower`}</h1>
    <p>{`The summer was almost gone, I was tired and thinking that perhaps being a flight attendant wasn't my dream job. The job itself was pretty fun when things went well, the company that I have been working for - and still am - is pretty amazing to work for and I am happy to be part of this family. `}</p>
    <p>{`But I was still demotivated and unhappy.`}</p>
    <p>{`It was 2:30 am. I was getting ready to go to work and in the shower, a thought hit me like a truck.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Your passion is the thing that you can get lost and don't even notice the time passing. You forget to eat because you are so immersed in the task at hand."`}</p>
    </blockquote>
    <p>{`I followed that thought and realised that I had this feeling when I was doing the MIT MOOC - introduction to computer science with python (`}<a parentName="p" {...{
        "href": "https://theflying.dev/digital-garden/genesis/"
      }}>{`Read more in the previous post`}</a>{`)`}</p>
    <h1>{`Getting back to code`}</h1>
    <p>{`This was time, to start another journey into trying to learn how to code once more. I started by doing the courses in `}<a parentName="p" {...{
        "href": "http://codecademy.com"
      }}>{`codecademy`}</a>{`.`}</p>
    <p>{`The next step was enrolling in the MIT MOOC again and actually finish it this time.  After that, it was time to start taking and doing every single tutorial that I could get my hand on.`}</p>
    <p>{`This put me into tutorial hell, but that is a story for another time...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      